import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['tab', 'main', 'accordion', 'content'];

  initialize() {
    if ((matchMedia && matchMedia('(min-width: 768px)').matches) || !this.hasAccordionTarget) {
      this.type = 'tab';
      this.tab();
    } else {
      this.type = 'accordion';
      this.mainTargets.forEach((main) => {
        main.classList.remove('d-none');
      });
    }
  }

  resize() {
    setTimeout(() => this.initialize(), 1000);
  }

  change(event) {
    event.preventDefault();
    this.index = this[`${this.type}Targets`].indexOf(event.currentTarget);
  }

  tab() {
    this.tabTargets.forEach((tab, index) => {
      const main = this.mainTargets[index];
      tab.classList.toggle('c-tabs__nav-item--is-active', index === this.index);
      main.classList.toggle('d-none', index !== this.index);
    });
  }

  accordion() {
    this.accordionTargets.forEach((tab, index) => {
      const content = this.contentTargets[index];
      if (!content.classList.contains('closed')) {
        tab.classList.remove('c-tabs__accordion--is-active');
        content.classList.add('closed');
      } else {
        tab.classList.toggle('c-tabs__accordion--is-active', index === this.index);
        content.classList.toggle('closed', index !== this.index);
      }
    });
  }

  get index() {
    return parseInt(this.data.get('index') || 0, 10);
  }

  set index(value) {
    this.data.set('index', value);
    this[this.type]();
  }
}
