import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['btn', 'tab', 'panelInner'];

  static values = {
    defaultTab: String,
  };

  // Initialize a variable to keep track of the previous mobile state
  previousIsMobile = window.matchMedia('(max-width: 1200px)').matches;

  connect() {
    // Set the initial state based on the current window size
    this.isMobile = this.previousIsMobile;
    this.adjustTabsForDevice();

    // Add the resize event listener
    window.addEventListener('resize', this.handleResize.bind(this));
  }

  handleResize() {
    // Update the isMobile state based on the current window size
    this.isMobile = window.matchMedia('(max-width: 1200px)').matches;

    // Only adjust tabs if there was a change in state
    if (this.isMobile !== this.previousIsMobile) {
      this.adjustTabsForDevice();
      // Update the previousIsMobile state for the next resize event
      this.previousIsMobile = this.isMobile;
    }
  }

  adjustTabsForDevice() {
    // Execute changes only if transitioning through the breakpoint
    if (this.isMobile) {
      // On mobile, close all tabs but keep the buttons visible
      this.tabTargets.forEach((tab) => {
        tab.hidden = false;
      });
      this.panelInnerTargets.forEach((panelInner) => {
        panelInner.hidden = true;
      });
      this.btnTargets.forEach((btn) => btn.classList.remove('active'));
    } else {
      // On desktop, open the default tab
      this.setDefaultTab();
    }
  }

  setDefaultTab() {
    // On desktop, show the default tab or the first if no default is specified
    const defaultTab = this.defaultTabValue || this.btnTargets[0].id;
    this.showTab(defaultTab);
  }

  showTab(tabId) {
    // Close all tabs first
    this.tabTargets.forEach((tab) => {
      tab.hidden = true;
    });
    this.panelInnerTargets.forEach((panelInner) => {
      panelInner.hidden = true;
    });
    this.btnTargets.forEach((btn) => btn.classList.remove('active'));

    // Show the selected tab and button
    const selectedTab = this.tabTargets.find((tab) => tab.id === tabId);
    const selectedPanelInner = this.panelInnerTargets.find(
      (panelInner) => panelInner.closest('.drv-tabs__panel').id === tabId,
    );
    const selectedBtn = this.btnTargets.find((btn) => btn.id === tabId);

    if (selectedTab && selectedPanelInner && selectedBtn) {
      selectedTab.hidden = false;
      selectedPanelInner.hidden = false;
      selectedBtn.classList.add('active');
    }
  }

  select(event) {
    const btnId = event.currentTarget.id;

    if (this.isMobile) {
      // Toggle the panel inner visibility on mobile
      this.toggleTab(btnId);
    } else {
      // On desktop, show the clicked tab
      this.showTab(btnId);
    }
  }

  toggleTab(btnId) {
    const panelInner = this.panelInnerTargets.find(
      (panelInnerTarget) => panelInnerTarget.closest('.drv-tabs__panel').id === btnId,
    );

    if (panelInner) {
      const isHidden = panelInner.hidden;
      panelInner.hidden = !isHidden;
      this.btnTargets.forEach((btn) => btn.classList.toggle('active', btn.id === btnId && isHidden));
    }
  }
}
