import { Controller } from '@hotwired/stimulus';
// eslint-disable-next-line import/no-unresolved
import { createPopper } from '@popperjs/core';

export default class extends Controller {
  static targets = ['button', 'tooltip'];

  static values = {
    placement: { type: String, default: 'top' },
    offset: { type: Array, default: [0, 5] },
  };

  connect() {
    // Create a new Popper instance
    this.popperInstance = createPopper(this.buttonTarget, this.tooltipTarget, {
      placement: this.placementValue,
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: this.offsetValue,
          },
        },
        {
          name: 'arrow',
          options: {
            padding: 5, // 5px from the edges of the popper
          },
        },
        {
          name: 'preventOverflow',
          options: {
            altAxis: true,
            padding: 40,
          },
        },
      ],
    });

    const showEvents = ['mouseenter', 'focus'];
    const hideEvents = ['mouseleave', 'blur'];

    showEvents.forEach((event) => {
      this.element.addEventListener(event, this.show);
    });

    hideEvents.forEach((event) => {
      this.element.addEventListener(event, this.hide);
    });
  }

  show = () => {
    this.tooltipTarget.setAttribute('data-show', '');

    this.popperInstance.setOptions((options) => ({
      ...options,
      modifiers: [...options.modifiers, { name: 'eventListeners', enabled: true }],
    }));

    // We need to tell Popper to update the tooltip position
    // after we show the tooltip, otherwise it will be incorrect
    this.popperInstance.update();
  };

  hide = () => {
    this.tooltipTarget.removeAttribute('data-show');

    this.popperInstance.setOptions((options) => ({
      ...options,
      modifiers: [...options.modifiers, { name: 'eventListeners', enabled: false }],
    }));
  };

  // Destroy the Popper instance
  disconnect() {
    if (this.popperInstance) {
      this.popperInstance.destroy();
    }
  }
}
