import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['container'];

  load(event) {
    if (this.element.getAttribute('data-ui-other-offers-loaded')) {
      return;
    }

    this.containerTarget.style.position = 'relative';
    this.containerTarget.style.minHeight = '140px';
    this.containerTarget.style.height = '140px';

    const loader = document.createElement('div');
    loader.id = 'loader';
    this.containerTarget.appendChild(loader);

    Rails.ajax({
      url: event.target.getAttribute('data-ui-other-offers-path'),
      type: 'get',
      success: (result) => {
        const selector = '[data-render-target="groups#derivatives"]';
        $(selector).replaceWith($(selector, result));
        this.element.setAttribute('data-ui-other-offers-loaded', true);
      },
    });
  }
}
