import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['btn', 'tab'];

  static values = {
    defaultTab: String,
    type: String,
  };

  connect() {
    // Hide all tabs by default
    this.tabTargets.forEach((x) => {
      x.hidden = true;
    });

    // Open default tab is specified
    try {
      const selectedBtns = this.btnTargets.filter((element) => element.id === this.defaultTabValue);
      const selectedTab = this.tabTargets.find((element) => element.id === this.defaultTabValue);
      selectedTab.hidden = false;
      // selectedBtns.classList.add("active")
      selectedBtns.map((x) => x.classList.add('active'));
    } catch {
      /* empty */
    }
  }

  select(event) {
    // find tab with same id as clicked btn
    let selectedTab;

    if (this.typeValue === 'boolean') {
      selectedTab = this.tabTargets.find((element) => element.id === `${event.target.checked}`);
    } else {
      selectedTab = this.tabTargets.find((element) => element.id === event.currentTarget.id);
    }

    if (this.typeValue === 'boolean') {
      console.log(selectedTab);
    }

    if (selectedTab.hidden) {
      // Close current tab and open selected tab
      this.tabTargets.forEach((x) => {
        x.hidden = true;
      }); // hide all tabs
      this.btnTargets.forEach((x) => x.classList.remove('active')); // deactive all btns
      selectedTab.hidden = false; // show current tab
      selectedTab.maxHeight = `${selectedTab.scrollHeight}px`;
      const selectedBtns = this.btnTargets.filter((element) => element.id === event.currentTarget.id);
      selectedBtns.map((x) => x.classList.add('active'));
      // event.currentTarget.classList.add("active") // active current btn
    } else {
      selectedTab.hidden = true; // hide current tab
      event.currentTarget.classList.remove('active'); // active current btn
    }
  }
}
