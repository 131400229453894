// https://www.telerik.com/blogs/building-html5-form-validation-bubble-replacements

import { Controller } from '@hotwired/stimulus';

const classes = {
  field: { valid: 'is-valid', invalid: 'is-invalid' },
  message: { valid: 'valid-feedback', invalid: 'invalid-feedback' },
};

export default class extends Controller {
  addMessage(field, type, message) {
    if (field.classList.contains(classes.field[type])) return;

    field.classList.add(classes.field[type]);
    field.parentNode.insertAdjacentHTML('beforeend', `<div class="${classes.message[type]}">${message}</div>`);
  }

  removeMessage(field, type) {
    if (!field.classList.contains(classes.field[type])) return;

    field.classList.remove(classes.field[type]);
    field.parentNode.removeChild(field.parentNode.querySelector(`.${classes.message[type]}`));
  }

  connect() {
    const form = this.element;

    // rely on the good default browser form field validation
    form.removeAttribute('novalidate');

    const requiredFields = form.querySelectorAll('[name][required]');

    Array.from(requiredFields).forEach((requiredField) => {
      requiredField.addEventListener('blur', (event) => {
        const isValid = event.target.checkValidity();

        if (isValid) {
          this.removeMessage(event.target, 'invalid');
          this.addMessage(event.target, 'valid', 'Thank you.');
        } else {
          // checkValidity will automatically trigger `invalid` on the field
        }
      });

      requiredField.addEventListener('invalid', (event) => {
        // suppress the default form validation messages
        event.preventDefault();

        this.removeMessage(event.target, 'valid');
        this.addMessage(event.target, 'invalid', 'Please check and enter it again.');
      });
    });
  }
}
